import React, { Component } from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Noimage from '../components/noimage'
import Eyecatch from '../components/eyecatch'
import Card from '../components/card'

class PostsTemplate extends Component {
  render() {
    const edges = this.props.pageContext.group
    const path = this.props.pageContext.pathPrefix

    const { group, index, first, last, pageCount } = this.props.pageContext
    const previousUrl = index - 1 === 1 ? '' : 'page/' + (index - 1).toString()
    const nextUrl = 'page/' + (index + 1).toString()

    const NavLink = props => {
      if (!props.flag) {
        return <Link to={props.url}>{props.text}</Link>
      } else {
        return ''
      }
    }

    return (
      <Layout>
        <SEO title={'トップページ'} url={path} />
        <article className={'posts'}>
          {edges.map(({ node }) => (
            <Card key={node.slug} post={node} />
          ))}
          <nav className="posts-navigation">
            <div className="nav-links">
              <div className="nav-previous">
                <NavLink
                  flag={first}
                  url={path + '/' + previousUrl + '/'}
                  text="Previous"
                />
              </div>
              <div className="nav-next">
                <NavLink
                  flag={last}
                  url={path + '/' + nextUrl + '/'}
                  text="Next"
                />
              </div>
            </div>
          </nav>
        </article>
      </Layout>
    )
  }
}

export default PostsTemplate
